import { mapActions, mapGetters } from 'vuex';
import Notification from '@Shared/legacy/Notification';

import { nameSpace as notificationsNameSpace } from '@/vue/stores/Notifications/NotificationsStore';

export default {

  computed: {
    ...mapGetters(notificationsNameSpace, [
      'getNotificationList',
    ]),
  },

  methods: {
    createNotification(text) {
      return Notification.create(text);
    },

    ...mapActions(notificationsNameSpace, [
      'addNotification',
      'deleteNotification',
      'deleteAllNotifications',
    ]),
  },

};
