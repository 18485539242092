import { mapActions, mapGetters } from 'vuex';
import { nameSpace as companyNameSpace } from '@/vue/stores/Company/CompanyStore';

export default {
  computed: {
    ...mapGetters(companyNameSpace, [
      'getCompanyData',
      'userHasCompany',
    ]),
  },

  methods: {
    ...mapActions(companyNameSpace, [
      'setCompanyData',
      'resetCompanyStore',
    ]),
  },
};
