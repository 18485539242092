import Vue from 'vue';
import Router from 'vue-router';
import { authService } from '@emobg/access-utils';

import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import siftScienceSnippet from '@/services/SiftScienceSnippet';

import SignUpRouter from '@Account/SignUp/router';
import ResetPasswordRouter from '@Account/ResetPassword/routes';

import * as UserDataModule from '@/vue/stores/UserData/UserDataStore';
import { getPageTrackingData, getUserTrackingDataByGetters } from '@/helpers/user/tracking/trackingData';
import { GTMEvents } from '@/constants/gtm';
import { SIGNUP_ROUTE_PATTERN } from '@/constants/path.constants';

import BusinessProfileRoutes from '@Profile/Business/router';
import PersonalProfileRoutes from '@Profile/Personal/router';
import AccountSettingsRoutes from '@Account/Settings/router';
import BookingsRoutes from '@Bookings/router';
import SharedRoutes from '@Shared/router';
import InviteFriendRoutes from '@Profile/InviteFriend/router';
import LegalDocuments, { IndexLegalDocumentsRoute } from '@/domains/Document/Legal/router';
import EmailValidationRoutes from '@Account/UserEmailVerification/router';
import MFAVerificationRoutes from '@Account/MFAVerification/router';
import PhishingBadgeRoutes from '@Shared/PhishingBadge/router';
import CompanyAdminRoutes from '@Profile/Business/CompanyAdmin/router';

const CarpoolingView = () => import(/* webpackChunkName: "carpooling" */'@/domains/Carpooling/Carpooling');
const DamageReportView = () => import(/* webpackChunkName: "damages" */'@Vehicle/DamageReportView');

const LinkBusinessProfile = () => import(/* webpackChunkName: "profile" */'@Profile/LinkBusinessProfile/LinkBusinessProfile');
const LinkAdminAccount = () => import(/* webpackChunkName: "profile" */'@Profile/LinkAdminAccount/LinkAdminAccount');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkActiveClass: 'phNavbar_link--active',
  routes: [
    ...SignUpRouter,
  ].concat([
    {
      path: '/vehicle-damage-report/:vehicleUUID',
      name: 'Vehicle Damage Report',
      component: DamageReportView,
      meta: {
        requireAuth: false,
        requireLoader: true,
        responsive: true,
      },
    },
    {
      path: '/terms_and_conditions',
      name: 'Terms and Conditions',
      redirect: { name: IndexLegalDocumentsRoute.name },
      meta: {
        requireAuth: false,
      },
    },
    {
      path: '/link-account',
      name: 'LinkAccount',
      component: LinkBusinessProfile,
      meta: {
        requireAuth: true,
        responsive: true,
      },
    },
    {
      path: '/link-admin-account',
      name: 'LinkAdminAccount',
      component: LinkAdminAccount,
      meta: {
        requireAuth: true,
        responsive: true,
      },
    },
    {
      path: '/impersonate',
      name: 'ImpersonationView',
      async beforeEnter(to, from) {
        const redirect = to.query.redirect_to || '/';

        if (new RegExp(/\/signup[^$]*$/).test(from.path)) {
          const storageData = JSON.parse(localStorage.getItem('vuex')).Refactor.SignUp;
          try {
            const email = get(storageData, 'User.data.email') || get(storageData, 'Company.data.companyEmail');
            const password = get(storageData, 'User.data.password') || get(storageData, 'Company.data.password');
            localStorage.removeItem('vuex');
            await authService.instance.loginAuth0(email, password);
          } catch (e) {
            window.location = redirect;
          }
        } else {
          const accessToken = to.query.access_token;
          const idpUUID = to.query.idp_uuid;
          const expiresIn = to.query.expires_at;
          const expiresAt = JSON.stringify(
            (expiresIn * 1000) + (new Date().getTime()),
          );

          if (!!accessToken && !!expiresAt && !!idpUUID) {
            localStorage.setItem('expiresAt', expiresAt);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('impersonate_accessToken', accessToken);
            localStorage.setItem('impersonate_idpUUID', idpUUID);
            localStorage.setItem('is_impersonate', 'true');
            window.location = redirect;
          }
        }
      },
      meta: {
        requireLoader: true,
        requireAuth: false,
        responsive: true,
      },
    },
    {
      path: '/booking/carpooling',
      name: 'carpooling',
      component: CarpoolingView,
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '*',
      redirect: '/',
      meta: {
        requireAuth: true,
        responsive: true,
      },
    },
  ]).concat(
    PersonalProfileRoutes,
    AccountSettingsRoutes,
    InviteFriendRoutes,
    BusinessProfileRoutes,
    CompanyAdminRoutes,
    BookingsRoutes,
    LegalDocuments,
    ResetPasswordRouter,
    SharedRoutes,
    EmailValidationRoutes,
    MFAVerificationRoutes,
    PhishingBadgeRoutes,
  ),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach(to => {
  siftScienceSnippet.track();
  const store = get(router, 'app.$store');
  const routePath = get(to, 'path');
  const isLoggedIn = !isNil(get(store, `getters[${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userUuid}]`));
  const isSignupRoute = SIGNUP_ROUTE_PATTERN.test(routePath);

  if (Vue.gtm && store && (isLoggedIn || isSignupRoute)) {
    window.dataLayer?.push({
      page_data: null,
    });

    const userData = isLoggedIn ? getUserTrackingDataByGetters({ getters: store.getters }) : undefined;
    const language = store.getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userLocale}`];
    const country = store.getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userCountry}`];

    const pageData = getPageTrackingData({ newRoute: to, document, window, country, language });
    Vue.gtm.trackEvent({
      event: GTMEvents.pageView,
      page_data: omitBy(pageData, isNil),
      ...(isLoggedIn ? { user_data: userData } : {}),
    });
  }
});

export default router;
