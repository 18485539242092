import get from 'lodash/get';
import Crypto from 'crypto';
import { ENV } from '@emobg/web-utils';
import config from '@/config';

const currentEnvironment = get(config, 'data.environment');
const isLocalhost = currentEnvironment === ENV.local;
const isProduction = currentEnvironment === ENV.production;
const nonce = Crypto.randomBytes(16).toString('base64');

// TODO:: When COOKIE BANNER add condition into "enabled"
export default {
  id: config.data.gtmId,
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce, // Will add `nonce` to the script tag
  enabled: !isLocalhost, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: !isProduction, // Whether or not display console logs debugs (optional)
  loadScript: true, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [],
};
