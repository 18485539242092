import {
  mapActions,
  mapGetters,
} from 'vuex';
import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';

export default {
  computed: {
    ...mapGetters(UserDataNameSpace, {
      userData: 'getUserData',
    }),
  },

  methods: {
    ...mapGetters(UserDataNameSpace, ['getUserData', 'getUserGeolocation']),
    ...mapActions(UserDataNameSpace, ['setUserData', 'setUserGeolocation']),
  },
};
