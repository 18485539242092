import { mapActions, mapGetters } from 'vuex';
import { nameSpace as bookingTypeNameSpace } from '@/vue/stores/BookingType/BookingTypeStore';

import BookingType from '@Bookings/legacy/BookingType';

import CarsharingRules from '@Shared/legacy/CarsharingRules';
import LongDistanceRules from '@Shared/legacy/LongDistanceRules';
import InterventionRules from '@Shared/legacy/InterventionRules';

export default {
  computed: {
    ...mapGetters(bookingTypeNameSpace, {
      bookingTypeCollection: 'getBookingTypeCollection',
      currentBookingType: 'getCurrentBookingType',
    }),
  },

  methods: {
    ...mapActions(bookingTypeNameSpace, ['setBookingTypeCollection', 'setCurrentBookingType']),

    getBookingRulesByCurrentBookingType(CSOperatorConfig, bookingType) {
      const type = bookingType || this.currentBookingType.getCode();

      switch (type) {
        case BookingType.carsharingCode:
          return new CarsharingRules(CSOperatorConfig);
        case BookingType.longDistanceCode:
          return new LongDistanceRules(CSOperatorConfig);
        case BookingType.intervention:
          return new InterventionRules(CSOperatorConfig);
        default:
          throw new Error('Current booking type is not defined');
      }
    },
  },
};
