import 'core-js';
import axios from 'axios';
import config from '@/config';

import { decryptObject } from '@/utils/crypto';

/* eslint-disable */
// We should only start loading the application when remote configuration
// is loaded and global config object is updated. Otherwise the rest of the modules
// won't have the remote configuration overrides.

axios.get(`${config.data.serverUrl}/middleware/config`).then(({ data }) => {
  config.updateConfig(decryptObject(data.env));
  require('./app');
});
