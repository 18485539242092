import get from 'lodash/get';
import size from 'lodash/size';

import { mapActions, mapGetters } from 'vuex';
import { nameSpace as profileNameSpace } from '@Profile/store';

export default {
  computed: {
    ...mapGetters(profileNameSpace, {
      profileCollection: 'getProfileCollection',
      currentProfile: 'getCurrentProfile',
      hasBusinessProfile: 'hasBusinessProfile',
      hasPersonalProfile: 'hasPersonalProfile',
      getProfileByUUID: 'getProfileByUUID',
    }),

    // TODO: [CF-58] Rename this shorter, create a getter for this and remove Mixin usage
    canCurrentProfileUseCostAllocation() {
      return this.currentProfile
      && this.currentProfile.isBusinessProfile()
      && this.currentProfile.getCompanyData()
      && this.currentProfile.getCompanyData().isCostAllocationConfigurable();
    },

    isProfileCostAllocationSet() {
      // When is personal profile, has not company or
      // company does not use cost allocations...
      // we will assume it is set.
      if (!this.canCurrentProfileUseCostAllocation) {
        return true;
      }

      return get(this, 'currentProfile.mandatory_profile_cost_allocations_set', true);
    },

    hasOnlyBusinessProfile() {
      return size(this.profileCollection) === 1 && this.hasBusinessProfile;
    },
  },

  methods: {
    ...mapActions(profileNameSpace, ['setProfileCollection', 'setCurrentProfile']),
  },
};
