import * as Events from '@/constants/event-handlers';

export default {
  computed: {
    eventHandler: function fn() {
      return this.$root;
    },

    events: () => Events,
  },
};
