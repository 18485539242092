import get from 'lodash/get';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { mapGetters } from 'vuex';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import { getCurrentCity } from '@/stores/City/CityMapper';

export default {

  computed: {
    ...mapGetters(UserDataNameSpace, {
      userData: 'getUserData',
    }),
    getCurrentCity,
  },

  methods: {
    ...mapGetters(UserDataNameSpace, ['getUserData', 'getUserGeolocation']),

    // Tracking method
    // TODO: https://europcarmobility.atlassian.net/browse/SBCCC-533
    trackSegment({ name, data = {}, cache = false }) {
      if (!name) {
        logger.message('Segment event name is missing', LOG_TYPE.warning);
        return;
      }

      const trackingData = (isString(data) || isNumber(data))
        ? { value: data }
        : data;

      const sesionStorageEvent = sessionStorage.getItem(name);
      if (this.isSegmentLoaded() && !sesionStorageEvent) {
        if (cache) {
          sessionStorage.setItem(name, true);
        }
        if (isEmpty(trackingData)) {
          window.analytics.track(name);
        } else {
          const dataToSendByCompanyOwnership = this.removeNotAllowedKeyIfCompanyOwnsData(trackingData);
          window.analytics.track(name, dataToSendByCompanyOwnership);
        }
      }
    },

    // Tracking Group
    trackSegmentGroup(id, object = {}) {
      const dataToSendByCompanyOwnership = this.removeNotAllowedKeyIfCompanyOwnsData(object);
      if (this.isSegmentLoaded()) {
        window.analytics.group(id, dataToSendByCompanyOwnership);
      }
    },

    trackSegmentIdentify(id, object = {}) {
      const dataToSendByCompanyOwnership = this.removeNotAllowedKeyIfCompanyOwnsData(object);
      if (this.isSegmentLoaded()) {
        window.analytics.identify(id, dataToSendByCompanyOwnership);
      }
    },

    /**
     * Returns properties to identify a user / tracking id
     * @param user {object} - Response from /from-idp endpoint
     * @return {object}
     */
    getIdentifyProperties(user = {}) {
      const {
        email = '',
        first_name: firstName = '',
        last_name: lastName = '',
        idp_uuid: userUuid = '',
        cs_provider_uuid: operatorUuid = '',
        tracking_id: trackingId = '',
      } = user;

      return {
        name: `${firstName} ${lastName}`,
        email,
        firstName,
        lastName,
        idp_uuid: userUuid,
        cs_operator_fk: operatorUuid,
        platform: 'webapp',
        tracking_id: trackingId,
      };
    },

    /**
     * @deprecated
     */
    getSegmentUserId() {
      return window.analytics.user().id();
    },

    isSegmentLoaded() {
      return window.analytics;
    },

    distanceBetweenLocations(google, firstLocation, secondLocation) {
      return (
        !isNil(get(firstLocation, 'lat'))
          && !isNil(get(firstLocation, 'lng'))
          && !isNil(get(secondLocation, 'lat'))
          && !isNil(get(secondLocation, 'lng'))
      )
        ? this.calculateDistance(google, firstLocation, secondLocation)
        : -1;
    },

    // Method to calculate distances between 2 points
    calculateDistance(googleInstance, cord1, cord2) {
      const getCord = (lat, lng) => new googleInstance.maps.LatLng(parseFloat(lat), parseFloat(lng));

      let result = null;
      if (cord1 && cord2 && googleInstance) {
        result = googleInstance.maps.geometry.spherical.computeDistanceBetween(
          getCord(cord1.lat, cord2.lng),
          getCord(cord2.lat, cord2.lng),
        );
        return parseFloat((result / 1000).toFixed(2));
      }
      return null;
    },

    getCurrencyCode(symbol) {
      switch (symbol) {
        case '€': return 'EUR';
        case '$': return 'USD';
        case '£': return 'GBP';
        default: return 'EUR';
      }
    },
    getFeatureFlags() {
      const featureFlags = {};
      const flags = (this.$featureFlag && this.$featureFlag.flags) || {};
      Object.entries(flags).forEach(([key, value]) => {
        if (value) {
          featureFlags[key] = value;
        }
      });
      return featureFlags;
    },
    removeNotAllowedKeyIfCompanyOwnsData(dataToSend) {
      const userSegmentData = {
        uuid: get(this.userData, 'company.uuid'),
        city: get(this.getCurrentCity, 'code'),
      };

      const sendObjectWithUserData = { ...dataToSend, ...userSegmentData };
      const companyOwnsEmployeeData = get(this.userData, 'company.owns_employee_data', false);

      if (companyOwnsEmployeeData) {
        return omit(sendObjectWithUserData, [
          'uuid',
          'name',
          'firstName',
          'lastName',
          'email',
          'phone',
          'idp_uuid',
        ]);
      }

      return sendObjectWithUserData;
    },
  },
};
