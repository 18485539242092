import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import {
  BUTTON_TYPES,
  COLORS,
  COLOR_VARIANTS,
  FACES,
  GRAYSCALE,
  ICONS,
  ICONS_SIZES,
  INPUT_TYPES,
  LOADER,
  OS_ICONS,
  PLACEMENTS,
  SIZES,
} from '@emobg/web-components';

import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';

export default {
  created() {
    this.FACES = FACES;
    this.COLORS = COLORS;
    this.COLOR_VARIANTS = COLOR_VARIANTS;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.GRAYSCALE = GRAYSCALE;
    this.ICONS = ICONS;
    this.OS_ICONS = OS_ICONS;
    this.ICONS_SIZES = ICONS_SIZES;
    this.INPUT_TYPES = INPUT_TYPES;
    this.LOADER = LOADER;
    this.PLACEMENTS = PLACEMENTS;
    this.SIZES = SIZES;
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.THEME_BUTTON_TYPES = THEME_BUTTON_TYPES;
  },
};
