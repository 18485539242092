import { mapActions, mapGetters } from 'vuex';
import { nameSpace as tariffNameSpace } from '@/vue/stores/Tariff/TariffStore';

export default {

  computed: {
    ...mapGetters(tariffNameSpace, {
      currentTariff: 'getCurrentTariff',
    }),
  },
  methods: {
    ...mapActions(tariffNameSpace, ['setCurrentTariff']),
  },
};
