import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

import { mapActions, mapGetters } from 'vuex';
import cfg from '@/config';
import { nameSpace as CSOperatorNameSpace } from '@/vue/stores/CSOperator/CSOperatorStore';

export default {
  data() {
    return {
      csOperatorCurrency: null,
      csOperatorMileage: null,
      csOperatorUserData: null,
    };
  },

  computed: {
    ...mapGetters(CSOperatorNameSpace, [
      'getCurrentCSOperator',
      'getCSOperatorCollection',
      'findOperatorBy',
      'findCSOperatorByUUID',
      'getVisitedCsOperator',
    ]),
    getMileageSymbol() {
      return (this.getCurrentCSOperator
        && this.getCurrentCSOperator.getConfiguration().mileage_symbol) || 'km';
    },
    getCurrencySymbol() {
      return (this.getCurrentCSOperator
        && this.getCurrentCSOperator.getConfiguration().currency_symbol) || '€';
    },
    getVisitedMileageSymbol() {
      return (this.getVisitedCsOperator
        && this.getVisitedCsOperator.getConfiguration().mileage_symbol) || 'km';
    },
    getVisitedCurrencySymbol() {
      return (this.getVisitedCsOperator
        && this.getVisitedCsOperator.getConfiguration().currency_symbol) || '€';
    },
  },

  methods: {
    ...mapActions(CSOperatorNameSpace, [
      'setCurrentCSOperator',
      'addCSOperatorInCollection',
      'setCSOperatorCollection',
      'setVisitedCsOperator',
      'fetchCSOperator',
      'resetCSOperatorStore',
    ]),

    setCsOperatorUserData(val) {
      this.csOperatorUserData = val;
    },

    getCSOperatorNameByFlavor() {
      const csOperator = get(cfg, 'data.csOperator');
      const nameByConfig = csOperator && upperFirst(csOperator.name);

      if (nameByConfig) {
        return nameByConfig;
      }

      const locationOrigin = get(window, 'location.origin');
      const nameByUrl = locationOrigin && locationOrigin.match(/:\/\/(.+)\..+$/);

      // If window variable exists,
      // we will pick the first domain
      // or: 'ecod' from 'https://webapp.ondemand.europcar.com/';
      if (nameByUrl) {
        return upperFirst(nameByUrl.split('.').pop());
      }

      return 'Ubeeqo';
    },
  },
};
