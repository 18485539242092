import { mapActions, mapGetters } from 'vuex';
import { nameSpace as UserInsuranceNameSpace } from '@/vue/stores/UserInsurance/UserInsuranceStore';

export default {

  computed: {
    ...mapGetters(UserInsuranceNameSpace, [
      'getUserInsurance',
    ]),
  },

  methods: {
    ...mapActions(UserInsuranceNameSpace, [
      'setUserInsurance',
      'resetUserDataStore',
    ]),
  },

};
