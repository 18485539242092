import { mapActions, mapGetters } from 'vuex';
import { nameSpace } from '@Shared/store/Loader/LoaderStore';

export default {

  computed: {
    ...mapGetters(nameSpace, {
      isLoaderActive: 'isLoaderActive',
    }),
  },

  methods: {
    ...mapActions(nameSpace, {
      toggleLoaderStatus: 'toggleLoaderStatus',
      setLoaderStatus: 'toggleLoaderStatus',
    }),
  },
};
